import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';

import Layout from 'components/layout';
import SEO from 'components/seo';
import Hero from 'components/sections/hero/hero';
import FeaturedLesson from 'components/sections/featured-lesson/featured-lesson';
import InfoSection from 'components/sections/info-section/info-section';
import ClassesSection from 'components/sections/classes-section/classes-section';
import Categories from 'components/sections/categories/categories';
import Button from 'components/ui/button/button';
import {
  AppStoreButton,
  GooglePlayButton,
} from 'components/ui/store-buttons/store-buttons';

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />
    <Hero />
    <Fade bottom distance={'30px'}>
      <FeaturedLesson lessons={data.allStrapiLesson.nodes} />
    </Fade>
    <Fade bottom distance={'30px'}>
      <InfoSection
        imageUrl={data.highlightedLessonImg.childImageSharp.fluid}
        title={
          'Creating authentic content on Instagram and monetizing your following'
        }
        text={
          'Join popular Instagram lifestyle photographer Diana Dalton (@dianadalt) as she breaks down the process of becoming an Instagram influencer.'
        }
      >
        <Button variant="primary">
          <i key="play-icon" className={`icon-player-play`}></i>Watch now
        </Button>
      </InfoSection>
    </Fade>
    <Fade bottom distance={'30px'}>
      <Categories />
    </Fade>
    <Fade bottom distance={'30px'}>
      <InfoSection
        imageUrl={data.classesImg.childImageSharp.fluid}
        title={'What will You discover?'}
        text={
          'Explore new skills, expand existing hobbies and get lost in creativity. What you find just might surprise and excite you.'
        }
        reverse
      >
        <Button variant="primary">Browse Classes</Button>
      </InfoSection>
    </Fade>
    <Fade bottom distance={'30px'}>
      <InfoSection
        imageUrl={data.projectsImg.childImageSharp.fluid}
        title={'Supportive community of likeminded creatives'}
        text={
          'Stages encourages you to broaden your horizons, learn new skills and create great things. Get inspired by amazing students’ Projects. '
        }
      >
        <Button variant="primary">Browse Projects</Button>
      </InfoSection>
    </Fade>
    <Fade bottom distance={'30px'}>
      <ClassesSection markers={data.allStrapiMaker.nodes} />
    </Fade>
    <Fade bottom distance={'30px'}>
      <InfoSection
        imageUrl={data.appsImg.childImageSharp.fluid}
        title={[
          'Learn from ',
          <span key="color-span" style={{ color: '#31D474' }}>
            anywhere
          </span>,
        ]}
        text={
          'Take classes on the go with the Stages app. Stream or download to watch in the woods, on the subway, or wherever you learn best.'
        }
        reverse
      >
        <AppStoreButton />
        <GooglePlayButton />
      </InfoSection>
    </Fade>
  </Layout>
);

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query {
    highlightedLessonImg: file(
      relativePath: { eq: "highlighted-lesson-img.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 592) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    classesImg: file(relativePath: { eq: "classes-img.png" }) {
      childImageSharp {
        fluid(maxWidth: 676) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    projectsImg: file(relativePath: { eq: "projects-section-img.png" }) {
      childImageSharp {
        fluid(maxWidth: 582) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    appsImg: file(relativePath: { eq: "apps-img.png" }) {
      childImageSharp {
        fluid(maxWidth: 554) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allStrapiLesson {
      nodes {
        id
        title
        category
        author {
          name
          avatar {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        cover {
          name
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allStrapiMaker {
      nodes {
        avatar {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        classesAmount {
          id
        }
        name
        studentsAmount
        occupation
      }
    }
  }
`;

export default IndexPage;
