import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image/withIEPolyfill';

import styles from './featured-carousel-item.module.scss';

const FeaturedCarouselItem = ({ lesson }) => (
  <div className={styles.root}>
    <Img
      fluid={lesson.cover.childImageSharp.fluid}
      objectFit="cover"
      objectPosition="50% 50%"
      alt=""
      className={styles.coverBlock}
    />
    <div className={styles.textBlock}>
      <p className={styles.label}>{lesson.category}</p>
      <p className={styles.title}>{lesson.title}</p>
      <div className={styles.authorBlock}>
        <Img
          fluid={lesson.author.avatar.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
          alt=""
          className={styles.authorImg}
        />
        <p className={styles.authorName}>{lesson.author.name}</p>
      </div>
    </div>
  </div>
);

FeaturedCarouselItem.propTypes = {
  lesson: PropTypes.objectOf({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    author: PropTypes.object.isRequired,
    category: PropTypes.string.isRequired,
    cover: PropTypes.object.isRequired,
  }),
};

export default FeaturedCarouselItem;
