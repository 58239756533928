import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/ui/button/button';

import CreationIcon from 'assets/icons/categories-creation-icon.inline.svg';
import TechIcon from 'assets/icons/categories-tech-icon.inline.svg';
import BusinessIcon from 'assets/icons/categories-business-icon.inline.svg';
import HealthIcon from 'assets/icons/categories-health-icon.inline.svg';

import styles from './category-card.module.scss';

const CategoryCard = ({ variant, title, text, buttonLabel }) => {
  switch (variant) {
    case 'blue':
      return (
        <div className={[styles.contentWrapper, styles.cardCreation].join(' ')}>
          <div
            className={[styles.imageWrapper, styles.imageCreation].join(' ')}
          >
            <CreationIcon />
          </div>
          <h4 className={styles.title}>{title}</h4>
          <p className={styles.text}>{text}</p>
          <Button classNames={[styles.button, styles.buttonCreation].join(' ')}>
            {buttonLabel}
          </Button>
        </div>
      );
    case 'yellow':
      return (
        <div className={[styles.contentWrapper, styles.cardTech].join(' ')}>
          <div className={[styles.imageWrapper, styles.imageTech].join(' ')}>
            <TechIcon />
          </div>
          <h4 className={styles.title}>{title}</h4>
          <p className={styles.text}>{text}</p>
          <Button classNames={[styles.button, styles.buttonTech].join(' ')}>
            {buttonLabel}
          </Button>
        </div>
      );
    case 'purple':
      return (
        <div className={[styles.contentWrapper, styles.cardBusiness].join(' ')}>
          <div
            className={[styles.imageWrapper, styles.imageBusiness].join(' ')}
          >
            <BusinessIcon />
          </div>
          <h4 className={styles.title}>{title}</h4>
          <p className={[styles.text, styles.textThird].join(' ')}>{text}</p>
          <Button classNames={[styles.button, styles.buttonBusiness].join(' ')}>
            {buttonLabel}
          </Button>
        </div>
      );
    case 'orange':
      return (
        <div className={[styles.contentWrapper, styles.cardHealth].join(' ')}>
          <div className={[styles.imageWrapper, styles.imageHealth].join(' ')}>
            <HealthIcon />
          </div>
          <h4 className={styles.title}>{title}</h4>
          <p className={styles.text}>{text}</p>
          <Button classNames={[styles.button, styles.buttonHealth].join(' ')}>
            {buttonLabel}
          </Button>
        </div>
      );
    default:
      return (
        <div className={[styles.contentWrapper, styles.cardCreation].join(' ')}>
          <div
            className={[styles.imageWrapper, styles.imageCreation].join(' ')}
          >
            <CreationIcon />
          </div>
          <h4 className={styles.title}>{title}</h4>
          <p className={styles.text}>{text}</p>
          <Button classNames={[styles.button, styles.buttonCreation].join(' ')}>
            {buttonLabel}
          </Button>
        </div>
      );
  }
};
CategoryCard.propTypes = {
  variant: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.node.isRequired,
  buttonLabel: PropTypes.string.isRequired,
};

export default CategoryCard;
