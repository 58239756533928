import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import CloseIcon from 'assets/icons/close-icon.svg';

import styles from './modal.module.scss';

const SModal = ({ children, onHide, ...props }) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      animation={false}
      centered
    >
      <Modal.Body>
        <CloseIcon onClick={onHide} className={styles.closeButton} />
        {children}
      </Modal.Body>
    </Modal>
  );
};
SModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default SModal;
