import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ItemsCarousel from 'react-items-carousel';
import { useWindowDimensions } from 'utils/useWindowDimensions';

import styles from './carousel.module.scss';

const Carousel = ({ isWhite, children }) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const { width } = useWindowDimensions();
  const chevronWidth = 40;
  const checkForNumberOfCards = () => {
    switch (true) {
      case width > 575:
        return 3;
      default:
        return 1;
    }
  };
  const changeActiveIndex = index => {
    if (index >= 0 && index <= children.length - 1) {
      setActiveItemIndex(index);
    }
  };
  const indicators = () => {
    return (
      <div className={styles.indicatorsContainer}>
        {children.map((item, index) => {
          if (activeItemIndex === index) {
            return (
              <div
                key={index}
                className={
                  isWhite
                    ? [styles.itemWhite, styles['itemWhite__active']].join(' ')
                    : [styles.item, styles['item__active']].join(' ')
                }
              />
            );
          } else {
            return (
              <div
                key={index}
                className={isWhite ? styles.itemWhite : styles.item}
              />
            );
          }
        })}
      </div>
    );
  };
  return (
    <div className={styles.root}>
      <ItemsCarousel
        requestToChangeActive={changeActiveIndex}
        activeItemIndex={activeItemIndex}
        numberOfCards={checkForNumberOfCards()}
        gutter={20}
        slidesToScroll={1}
        showSlither
        leftChevron={
          <i
            className={`icon-circle-arrow-left ${styles.arrowLeft}`}
            style={
              activeItemIndex === 0
                ? isWhite
                  ? { opacity: 0.5, color: 'white' }
                  : { opacity: 0.5 }
                : isWhite
                ? { opacity: 1, color: 'white' }
                : { opacity: 1 }
            }
          />
        }
        rightChevron={
          <i
            className={`icon-circle-arrow-right ${styles.arrowRight}`}
            style={
              activeItemIndex > children.length - 4
                ? isWhite
                  ? { opacity: 0.5, color: 'white' }
                  : { opacity: 0.5 }
                : isWhite
                ? { opacity: 1, color: 'white' }
                : { opacity: 1 }
            }
          />
        }
        chevronWidth={chevronWidth}
        firstAndLastGutter
        alwaysShowChevrons
        classes={{
          wrapper: '',
          itemsWrapper: styles.itemsWrapper,
          leftChevronWrapper: styles.leftChevronWrapper,
          rightChevronWrapper: styles.rightChevronWrapper,
        }}
      >
        {children}
      </ItemsCarousel>
      {width <= 575 && indicators()}
    </div>
  );
};

Carousel.propTypes = {
  isWhite: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
export default Carousel;
