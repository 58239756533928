import React, { useState } from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { Link } from 'gatsby';
import { useScroll } from 'utils/useScroll';

import Logo from 'components/ui/logo/logo';
import Button from 'components/ui/button/button';
import CategoriesDropdown from 'components/ui/categories-dropdown/categories-dropdown';
import MobileMenu from 'components/ui/mobile-menu/mobile-menu';
import InfoModal from 'components/ui/info-modal/info-modal';

import styles from './navbar.module.scss';

import OpenIcon from 'assets/icons/menu-open-icon.inline.svg';

const SNavbar = () => {
  const [isDropdownShown, toggleDropdown] = useState(false);
  const [isMenuShown, toggleMenu] = useState(false);
  const [infoModalShow, setInfoModalShow] = useState(false);

  const { scrollY } = useScroll();

  const [token, setToken] = useState(
    (typeof window !== 'undefined' && localStorage.getItem('token')) ||
      (typeof window !== 'undefined' && sessionStorage.getItem('token'))
  );
  const clickHandler = () => {
    setToken('');
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
  };

  return (
    <Navbar
      fixed="top"
      className={styles.navbar}
      style={
        scrollY > 100
          ? { backgroundColor: 'white' }
          : { backgroundColor: 'transparent' }
      }
    >
      <Container>
        <Logo variant="blue" />
        <OpenIcon
          className={styles.openIcon}
          onClick={() => toggleMenu(true)}
        />
        <Nav className={styles.nav}>
          <CategoriesDropdown
            isShown={isDropdownShown}
            toggle={toggleDropdown}
          />
          <Nav.Link href="#link">Projects</Nav.Link>
          <Nav.Link href="#home" className={styles.linkWithIcon}>
            <i className="icon-search"></i>
            Search
          </Nav.Link>
          {token ? (
            <Button
              variant="secondary"
              style={{ marginLeft: '1rem' }}
              onClick={clickHandler}
            >
              Logout
            </Button>
          ) : (
            <Link to="/auth/signup" className={styles.link}>
              <Button variant="secondary" style={{ marginLeft: '1rem' }}>
                Sign up
              </Button>
            </Link>
          )}
        </Nav>
      </Container>
      <MobileMenu isShown={isMenuShown} toggle={toggleMenu} />
      <InfoModal
        isShown={infoModalShow}
        hideModal={() => setInfoModalShow(false)}
      />
    </Navbar>
  );
};

export default SNavbar;
