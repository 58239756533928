import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';

import Carousel from 'components/ui/carousel/carousel';
import FeaturedCarouselItem from 'components/ui/featured-carousel-item/featured-carousel-item';

import styles from './featured-lesson.module.scss';

const FeaturedLesson = ({ lessons }) => (
  <Container fluid className={styles.root}>
    <h3 className={styles.title}>Find what captivates you</h3>
    <Carousel>
      {lessons.map(lesson => (
        <FeaturedCarouselItem key={lesson.id} lesson={lesson} />
      ))}
    </Carousel>
  </Container>
);

FeaturedLesson.propTypes = {
  lessons: PropTypes.array.isRequired,
};

export default FeaturedLesson;
