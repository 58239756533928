import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';

import Carousel from 'components/ui/carousel/carousel';
import ClassCarouselItem from 'components/ui/class-carousel-item/class-carousel-item';

import Vector1 from 'assets/icons/classes-vector-1.inline.svg';
import Vector2 from 'assets/icons/classes-vector-2.inline.svg';

import styles from './classes-section.module.scss';

const ClassesSection = ({ markers }) => (
  <ParallaxProvider>
    <Container fluid className={styles.root}>
      <h3 className={styles.title}>
        Classes taught by <span style={{ color: '#FFB91D' }}>real makers</span>
      </h3>
      <Parallax className={styles.vectorBox1} y={[-20, 20]} tagOuter="figure">
        <Vector1 className={styles.vector1} />
      </Parallax>
      <Parallax className={styles.vectorBox2} y={[-20, 20]} tagOuter="figure">
        <Vector2 className={styles.vector2} />
      </Parallax>
      <Carousel isWhite>
        {markers.map(marker => (
          <ClassCarouselItem key={marker.id} marker={marker} />
        ))}
      </Carousel>
    </Container>
  </ParallaxProvider>
);

ClassesSection.propTypes = {
  markers: PropTypes.array.isRequired,
};

export default ClassesSection;
