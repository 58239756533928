import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';

import Modal from 'components/ui/modal/modal';

import styles from './success-modal.module.scss';

const SuccessModal = ({ isShown, hideModal }) => {
  const data = useStaticQuery(graphql`
    query {
      infoModalImg: file(relativePath: { eq: "success-modal-img.png" }) {
        childImageSharp {
          fluid(maxWidth: 226) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Modal show={isShown} onHide={hideModal}>
      <div className={styles.modalBody}>
        <div className={styles.modalImageWrapper}>
          <Img fluid={data.infoModalImg.childImageSharp.fluid} alt="" />
        </div>
        <div className={styles.textBlock}>
          <h3 className="text-center">Awesome</h3>
          <p className="text-center">
            Thank you for subscribing to our launch updates. We’ve sent you a
            confirmation e-mail. You can cancel the subscription at any moment.
          </p>
        </div>
      </div>
    </Modal>
  );
};
SuccessModal.propTypes = {
  isShown: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default SuccessModal;
