import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

import styles from './input.module.scss';

const SInput = ({ type, placeholder, classNames, onInput, value = '' }) => (
  <Form.Control
    type={type}
    value={value}
    onInput={onInput}
    placeholder={placeholder}
    className={[styles.input, classNames].join(' ')}
  />
);
SInput.propTypes = {
  value: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  classNames: PropTypes.array,
  onInput: PropTypes.func.isRequired,
};

export default SInput;
