import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, NavDropdown } from 'react-bootstrap';

import Button from 'components/ui/button/button';

import styles from './categories-dropdown.module.scss';

const CategoriesDropdown = ({ isShown, toggle }) => {
  return (
    <NavDropdown
      title={
        <>
          Categories <i className="icon-chevron-bottom"></i>
        </>
      }
      id="basic-nav-dropdown"
      show={isShown}
      onMouseOver={() => {
        toggle(true);
      }}
      onMouseOut={() => {
        toggle(false);
      }}
    >
      <Row className={styles.wrapper}>
        <Col>
          <p className={styles.title}>Creation</p>
          <a href="/" className={styles.link}>
            Animation
          </a>
          <a href="/" className={styles.link}>
            Creative Writing
          </a>
          <a href="/" className={styles.link}>
            Film & Video
          </a>
          <a href="/" className={styles.link}>
            Fine Art
          </a>
          <a href="/" className={styles.link}>
            Graphic Design
          </a>
          <a href="/" className={styles.link}>
            Illustration
          </a>
          <a href="/" className={styles.link}>
            Music
          </a>
          <a href="/" className={styles.link}>
            Photography
          </a>
          <a href="/" className={styles.link}>
            UI/UX Design
          </a>
          <a href="/" className={styles.link}>
            Fashion Design
          </a>
          <a href="/" className={styles.link}>
            Interior Design
          </a>
        </Col>
        <Col>
          <p className={styles.title}>Technology</p>
          <a href="/" className={styles.link}>
            Web Development
          </a>
          <a href="/" className={styles.link}>
            Mobile Development
          </a>
          <a href="/" className={styles.link}>
            Data Science
          </a>
          <a href="/" className={styles.link} style={{ marginBottom: '1rem' }}>
            WordPress
          </a>
          <p className={styles.title}>Lifestyle</p>
          <a href="/" className={styles.link}>
            Crafts
          </a>
          <a href="/" className={styles.link}>
            Culinary
          </a>
          <a href="/" className={styles.link}>
            Health & Fitness
          </a>
          <a href="/" className={styles.link}>
            Languages
          </a>
        </Col>
        <Col>
          <p className={styles.title}>Business & Marketing</p>
          <a href="/" className={styles.link}>
            Business Analytics
          </a>
          <a href="/" className={styles.link}>
            Freelance & Enterpeneurship
          </a>
          <a href="/" className={styles.link}>
            Leadership & Management
          </a>
          <a href="/" className={styles.link}>
            Marketing
          </a>
          <a href="/" className={styles.link}>
            Productivity
          </a>
          <a href="/" className={styles.link}>
            Accounting
          </a>
          <a href="/" className={styles.link}>
            Finance
          </a>
          <a href="/" className={styles.link}>
            Public Speaking
          </a>
        </Col>
      </Row>
      <div className={styles.bottomButton}>
        <Button
          variant="secondary"
          style={{ marginBottom: '2rem' }}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          All Classes
        </Button>
      </div>
    </NavDropdown>
  );
};
CategoriesDropdown.propTypes = {
  isShown: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default CategoriesDropdown;
