import { useNetwork } from './useNetwork';

const config = {
  baseURL: process.env.GATSBY_CMS_URL,
};

export const useSignUp = () => {
  const [signUpData, signUpRequest] = useNetwork(
    { url: '/contacts', method: 'POST' },
    config
  );

  const signUp = email => {
    const data = { email };

    signUpRequest({ data });
  };

  return {
    signUp,
    result: signUpData,
  };
};
