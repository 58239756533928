import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import { Form } from 'react-bootstrap';

import Modal from 'components/ui/modal/modal';
import Input from 'components/ui/input/input';
import Button from 'components/ui/button/button';

import styles from './info-modal.module.scss';

const InfoModal = ({ isShown, hideModal }) => {
  const data = useStaticQuery(graphql`
    query {
      infoModalImg: file(relativePath: { eq: "info-modal-img.png" }) {
        childImageSharp {
          fluid(maxWidth: 226) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Modal show={isShown} onHide={hideModal}>
      <div className={styles.modalBody}>
        <div className={styles.modalImageWrapper}>
          <Img fluid={data.infoModalImg.childImageSharp.fluid} alt="" />
        </div>
        <div className={styles.textBlock}>
          <h3 className="text-center">Thank you for your interest!</h3>
          <p className="text-center mb-0">
            We are in the test phase now and need to explore the interest in our
            service before we go live. Live your email so we can notify you when
            we launch. Every person counts.
          </p>
        </div>

        <Form className={styles.formRow}>
          <Input
            type="text"
            placeholder="Enter your e-mail"
            classNames={['mr-2']}
          />
          <Button variant="primary" type="submit">
            Notify me
          </Button>
        </Form>
      </div>
    </Modal>
  );
};
InfoModal.propTypes = {
  isShown: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
};

export default InfoModal;
