import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import styles from './class-carousel-item.module.scss';

const ClassCarouselItem = ({ marker }) => (
  <div className={styles.root}>
    <div className={styles.coverBlock}>
      <Img
        fluid={marker.avatar.childImageSharp.fluid}
        alt=""
        className={styles.coverImg}
      />
    </div>
    <div className={styles.textBlock}>
      <p className={styles.name}>{marker.name}</p>
      <p className={styles.occupation}>{marker.occupation}</p>
      <div className={styles.infoBlock}>
        <p className={'nopadding'}>
          <span className={styles.classes}>
            Classes {marker.classesAmount.length}
          </span>{' '}
          <span>·</span>{' '}
          <span className={styles.students}>
            {marker.studentsAmount} Students
          </span>
        </p>
      </div>
    </div>
  </div>
);

ClassCarouselItem.propTypes = {
  marker: PropTypes.objectOf({
    studentsAmount: PropTypes.number.isRequired,
    classesAmount: PropTypes.object.isRequired,
    avatar: PropTypes.object.isRequired,
    occupation: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    students: PropTypes.number.isRequired,
  }),
};

export default ClassCarouselItem;
