import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';

import { useSignUp } from 'utils/useSignUp';

import Navbar from 'components/ui/navbar/navbar';
import Input from 'components/ui/input/input';
import Button from 'components/ui/button/button';
import Hint from 'components/ui/hero-hint/hero-hint';
import SuccessModal from 'components/ui/success-modal/success-modal';

import Vector1 from 'assets/icons/hero-vector-1.inline.svg';
import Vector2 from 'assets/icons/hero-vector-2.inline.svg';
import Vector3 from 'assets/icons/hero-vector-3.inline.svg';

import styles from './hero.module.scss';

const query = graphql`
  query {
    image1: file(relativePath: { eq: "hero-img-1.png" }) {
      childImageSharp {
        fluid(maxWidth: 195) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "hero-img-2.png" }) {
      childImageSharp {
        fluid(maxWidth: 180) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image3: file(relativePath: { eq: "hero-img-3.png" }) {
      childImageSharp {
        fluid(maxWidth: 230) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const Hero = () => {
  const data = useStaticQuery(query);
  const [successModalShow, setSuccessModalShow] = useState(false);
  const [email, setEmail] = useState('');
  const { result, signUp } = useSignUp();

  useEffect(() => {
    if (!result.data) return;

    setSuccessModalShow(true);
    setEmail('');
  }, [result]);

  const handleInput = e => {
    setEmail(e.target.value);
  };

  const handleClick = e => {
    e.preventDefault();
    signUp(email);
  };

  return (
    <ParallaxProvider>
      <Navbar />
      <Container fluid>
        <Row className={styles.row}>
          <Col
            lg={{ span: 12 }}
            xl={{ span: 6 }}
            className={styles.leftContainer}
          >
            <div className={styles.leftTextWrapper}>
              <h1>Begin a new stage of your career</h1>
              <p>
                Discover new skills, meet passionate teachers and become a part
                of the most helpful community of creatives in the world.
              </p>

              <Form>
                <div className={styles.formRow}>
                  <Input
                    type="email"
                    placeholder="Enter your e-mail"
                    classNames={['mr-2']}
                    value={email}
                    onInput={handleInput}
                  />
                  <Button variant="primary" type="submit" onClick={handleClick}>
                    Notify me
                  </Button>
                </div>
              </Form>

              <p className={styles.caption}>
                14 days trial <span>&#183;</span> No credit card required
              </p>
            </div>
          </Col>
          <Col
            lg={{ span: 12 }}
            xl={{ span: 6 }}
            className={styles.rightContainer}
          >
            <div className={styles.rightWrapper}>
              <Vector3 className={styles.vector3} />
              <Vector2 className={styles.vector2} />
              <Vector1 className={styles.vector1} />

              <Parallax
                className={styles.image1}
                y={[-20, 20]}
                tagOuter="figure"
              >
                <div style={{ position: 'relative' }}>
                  <Img fluid={data.image1.childImageSharp.fluid} alt="" />
                  <div className={styles.hint1}>
                    <Hint title="Ashley Wilson" text="Data Analyst" />
                  </div>
                </div>
              </Parallax>

              <Parallax
                className={styles.image2}
                y={[-20, 20]}
                tagOuter="figure"
              >
                <div style={{ position: 'relative' }}>
                  <Img fluid={data.image2.childImageSharp.fluid} alt="" />
                  <div className={styles.hint2}>
                    <Hint
                      title="Robert Bukowski"
                      text="Google Analytics Strategist"
                    />
                  </div>
                </div>
              </Parallax>

              <Parallax
                className={styles.image3}
                y={[-20, 20]}
                tagOuter="figure"
              >
                <div style={{ position: 'relative' }}>
                  <Img fluid={data.image3.childImageSharp.fluid} alt="" />
                  <div className={styles.hint3}>
                    <Hint title="Hanna Smith" text="Agile Coach" />
                  </div>
                </div>
              </Parallax>
            </div>
          </Col>
        </Row>
      </Container>
      <SuccessModal
        isShown={successModalShow}
        hideModal={() => setSuccessModalShow(false)}
      />
    </ParallaxProvider>
  );
};

export default Hero;
