import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import CategoryCard from 'components/ui/category-card/category-card';

import styles from './categories.module.scss';

const Categories = () => {
  return (
    <Container className={styles.mainContainer}>
      <div className={styles.mainTitle}>
        <h3>Categories</h3>
      </div>
      <Row className={styles.row}>
        <Col
          xs={{ span: 12 }}
          sm={{ span: 6 }}
          lg={{ span: 3 }}
          className={styles.mainCol}
        >
          <CategoryCard
            variant="blue"
            title="Creation & Design"
            text="Improve your physical and mental health. Find a new hobby and
          explore new activities."
            buttonLabel={'Browse Classes'}
          />
        </Col>
        <Col
          xs={{ span: 12 }}
          sm={{ span: 6 }}
          lg={{ span: 3 }}
          className={styles.mainCol}
        >
          <CategoryCard
            variant="yellow"
            title="Tech & Programming"
            text="Learn Web Development and build websites and mobile apps using HTML, CSS, Javascript and more."
            buttonLabel={'Learn Programming'}
          />
        </Col>
        <Col
          xs={{ span: 12 }}
          sm={{ span: 6 }}
          lg={{ span: 3 }}
          className={styles.mainCol}
        >
          <CategoryCard
            variant="purple"
            title="Business & Marketing"
            text="Grasp the skills you need to kick off, develop and conduct your business."
            buttonLabel={'Discover Business'}
          />
        </Col>
        <Col
          xs={{ span: 12 }}
          sm={{ span: 6 }}
          lg={{ span: 3 }}
          className={styles.mainCol}
        >
          <CategoryCard
            variant="orange"
            title="Health & Lifestyle"
            text="Improve your physical and mental health. Find a new hobby and explore new activities."
            buttonLabel={'Browse Health'}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Categories;
