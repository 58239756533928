import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Container, Row, Col } from 'react-bootstrap';

import styles from './info-section.module.scss';

const InfoSection = ({ imageUrl, title, text, children, reverse }) => {
  return (
    <Container className={styles.mainContainer}>
      <Row
        className={
          reverse ? [styles.row, styles['row__reverse']].join(' ') : styles.row
        }
      >
        <Col
          md={{ span: 12 }}
          lg={{ span: 6 }}
          className={styles.leftContainer}
        >
          <div className={styles.imageWrapper}>
            <Img
              fluid={imageUrl}
              className={
                reverse ? styles.imageCoreNoMargin : styles.imageCoreMargin
              }
              alt=""
            />
          </div>
        </Col>
        <Col
          md={{ span: 12 }}
          lg={{ span: 6 }}
          className={styles.rightContainer}
        >
          <div className={styles.contentWrapper}>
            <h2 className={styles.title}>{title}</h2>
            <p className={styles.text}>{text}</p>
            <div className={styles.buttonWrapper}>{children}</div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

InfoSection.propTypes = {
  imageUrl: PropTypes.object.isRequired,
  title: PropTypes.node.isRequired,
  text: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  reverse: PropTypes.bool,
  onClick: PropTypes.func,
};

export default InfoSection;
