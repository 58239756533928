import React from 'react';
import PropTypes from 'prop-types';

import Circle from 'assets/icons/hero-circle.inline.svg';
import styles from './hero-hint.module.scss';

const HeroHint = ({ title, text }) => (
  <>
    <Circle />
    <div className={styles.hintBody}>
      <p className={styles.hintTitle}>{title}</p>
      <p className={styles.hintText}>{text}</p>
    </div>
  </>
);

HeroHint.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default HeroHint;
